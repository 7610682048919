@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&family=Satisfy&display=swap');

.bio {
  background-color: white;
  min-height: 100vh;
  padding: 5% 0 0;

  &-title {
    display: flex;
    align-items: center;

    &>h2 {
      margin-left: auto;
      text-underline-offset: 10px;    
      font-weight: bolder;
      color: #4056A1;
    }

    &-line {
      width: 20%;
      border-bottom: solid 0.1rem #D79922;
    }
  }

  &-content {
    display: flex;
    width: 100%;

    &-left {
      display: block;
      text-align: center;
      width: 40%;
  
      &-profile {
        width: 100%;
      }
    }
  
    &-right {
      width: 60%;
      font-size: 18px;
      margin: 5% 0 0;
  
      &>h3 {
        text-align: center;
        color: #4056A1;
        font-size: 18px;
        font-weight: bold;
      }
  
      &-content {
        margin: 0 5%;
        display: flex;
        text-align: justify;
    
        &>p {
          padding: 2rem;
          line-height: 1.7rem;
          font-size: 16px;
        }
      }
    }
  }
}

.leftQuotationMark {
  width: 60px;
  align-self: flex-start;
}

.rightQuotationMark {
  width: 60px;
  align-self: flex-end;
  padding-top: 1rem;
}

.paint-banner {
  position: relative;
  z-index: 8;
  background-image: url(../../assets/paint-banner.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 50vh;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .bio-content {
    flex-direction: column;

    &-left {
      margin-top: 3rem;
      width: 100%;
    }

    &-right {
      width: 100%;

      & > h3 {
        margin: 2rem 0;
      }

      &-content > p {
        padding: 0;
      }
    }
  }

  .rightQuotationMark {
    width: 30px;
  }

  .leftQuotationMark {
    width: 30px;
  }
}