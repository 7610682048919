.delete {
  background-color: white;
  z-index: 10;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%); 
  width: 600px;
  padding-top: 0.5rem;

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 3rem 0;

    & > img {
      width: 130px;
    }

    &-container {
      display: flex;
      justify-content: space-evenly;
      align-items: flex-start;

    }
  }

  &-logo {
    width: 2.5rem;
    color: #b33939;
    border-radius: 50%;
    border-color: #b33939;  
  }

  &-text {
    margin-left: 1.5rem;
  }
  
  &-text > h2 {
    font-size: 20px;
  }

  &-text > p {
    font-size: 16px;
    color: grey;
  }

  &-footer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0.8rem;
    font-weight: 500;
    background-color: rgba(204, 204, 204, 0.21);
  }

  &-button {
    margin: 0 1rem 0;
    font-size: 16px;
    padding: 12px 16px;
    color: #b33939;
    border-radius: 06px;

    &--cancel {
      color: grey;
      text-decoration: underline;
      text-underline-offset: 4px;   
    }

    &--confirm {
      background-color: #ffcccc;
    }
  }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

  .delete {
    width: 100%;

    &-text {
      margin: 1rem 0 0 0;
    }

    &-header {
      flex-direction: column;
      padding: 1rem 0 0;

      &-container {
        text-align: center;
        flex-direction: column;
        align-items: center;
      }

      & > img {
        margin: 3em;
      }
    }

    &-footer {
      flex-direction: column;

   
    }

  }

}