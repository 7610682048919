@import url('https://fonts.googleapis.com/css2?family=Outfit&family=Roboto:wght@100;400&family=Yatra+One&display=swap');

.form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%); 
  background-color: white;
  z-index: 10;
  width: 600px;
  padding: 1rem;

  & >h2 {
    margin: 10px 0 30px;
    text-align: center;
  }
   
  &-content {
  flex-direction: column;
  width: 90%;
  margin: auto;
  }

  &-header {
    display: flex;
    width: 90%;
    flex-direction: column;
    margin: 1rem;
    font-family: roboto;

    &-dimensions {
      display: flex;
      justify-content: space-between;
      
      & > input {
        padding: 0.6rem;
        background-color: rgba(128, 128, 128, 0.182);
        border: transparent;
        border-radius: 5px;    
      }
    }
  
    & > label {
      margin: 20px 0 10px;
      display: flex;

      & > p {
        margin-left: 0.3em;
      }

      &:first-child {
        margin-top: 0rem;
      }
    }
    
    & > input, select {
      padding: 0.6rem;
      background-color: rgba(128, 128, 128, 0.182);
      border: transparent;
      border-radius: 5px;
    }

    & > p {
      margin-top: 20px;
      font-size: small;
      color: red;
    }
  }
  
  &-loader {
    margin: 0 auto;

    & > img {
      margin-top: 1rem;
    }
  }
  
  &-footer {

    &-button {
      display: flex;
      justify-content: space-evenly;
      & > button {
        margin-bottom: 1rem;
        padding: 10.5px 14px;
        border-radius: 0.5em;
      }

      &--cancel {
        text-decoration: underline;
        text-underline-offset: 4px;        
      }

      &--send {
        background-color: #D79922;
        color: white;
      }

    }
  }
  
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

  .form {
    width: 100%;

    &-content {
      width: 100%;
    }

    &-footer-button {
      flex-direction: column;
    }
  }

}