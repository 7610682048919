.back-to-top {
  position: fixed;
  z-index: 10;
  background-color: rgb(64, 86, 161);
  border-radius: 50%;
  bottom: 30px;
  right: 25px;

  &:hover {
    transform: scale(1.1);
  }

  &::after {
    animation: 3s ease-in 1s infinite reverse both running slidein;
    right: 40px;
    animation-delay: 250ms;

  }

  &_logo {
    width: 50%;
  }
}