@use 'sass:color';
@use '../../vars';

.pictureDetails {
  display: flex;
  width: 80%;
  margin: auto;

  &-left {    
    width: 50%;
    & > img {
      width: 70%;
    }
  }

  &-right {

    &-title {
      font-size: 20px;
    }

    &-infos {
      margin-top: 22px;
      font-size: 14px;

      & > h3 {
        margin-bottom: 10px;
      }

      & > p {
        color: grey;
      }
    }

    &-buttons {
      display: flex;
      align-items: center;
    }

    &-edit-info {
      margin-top: 20px;
      color: #b33939;
    }

  }
}

.pictureDetails-button {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  padding: 8px 10px;
  font-weight: bold;
  border-radius: 0.5em; 
  align-items: center;

  &--edit {
    margin-right: 0.5rem;
    transition: all 0.3s;
    background-color: #D79922;
    color: #fff;
  }
 
  &--remove {
    transition: all 0.3s;
    color: grey;
    text-decoration: underline;
    text-underline-offset: 4px;  
  }

  &--send {
    margin: 22px 0 22px;
    background-color: #f1f1f1;
    color: grey;
  }

  &--grey {
    margin: 22px 22px 22px 0;
    background-color: #f1f1f1;
    color: grey;

    & > div {
      fill: #fff;
      width: 10%;
    }
  }
}


/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .pictureDetails {
    flex-direction: column;

    &-left {
      width: 100%;
      
      & > img {
        width: 100%;
      }
    }

    &-right {
      margin-top: 2rem;
      width: 100%;

      & > form {
  
        & > div {
          margin: 20px 20px 0 0;
        }
      }
    }
  }
}