@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@500&display=swap');


html,
body {
  background-color: #FCFCFC;
  /* background-image: url('https://static.vecteezy.com/system/resources/previews/006/068/480/non_2x/marble-effect-banner-liquid-paint-pastel-horizontal-background-design-vector.jpghttps://freedesignfile.com/upload/2020/09/Acrylic-paint-pour-background-vector.jpghttps://img.freepik.com/free-photo/colorful-paint-canvas_53876-89428.jpg?w=1480&t=st=1675204855~exp=1675205455~hmac=d7ac50053e65dbc00894be901fc4f898de03acc79764fea33790977d7a2a2448https://img.freepik.com/free-photo/colorful-paint-canvas_53876-89428.jpg?w=1480&t=st=1675204855~exp=1675205455~hmac=d7ac50053e65dbc00894be901fc4f898de03acc79764fea33790977d7a2a2448https://e0.pxfuel.com/wallpapers/465/877/desktop-wallpaper-pastel-blue-solid-color-background-background-paint-mint-blue-pastel-thumbnail.jpghttps://i.pinimg.com/originals/ef/de/ae/efdeae709ea42996e8413c58b4cf39fb.jpg');
  background-size: cover; */
  padding: 0;
  margin: 0;
  font-family: 'Exo 2';
} 

button {
  background-color: transparent;
}

a {
  color: inherit;
  text-decoration: none;
}

h2 {
  font-size: 24px;
}

* {
  box-sizing: border-box;
}