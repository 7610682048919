@use 'sass:color';
@use '../../vars';
@import url('https://fonts.googleapis.com/css?family=Saira+Extra+Condensed');
@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');

*,
*::before,
*::after {
  box-sizing: border-box;
}

.menu {
  font-family: 'Saira Extra Condensed', sans-serif;
  letter-spacing: 1px;
  -webkit-font-smoothing: #a7a6a0;
  -moz-osx-font-smoothing: #a7a6a0;

  &-mobile {
    display: none;
  }
}

header {
  display: flex;
  flex-direction: column;
  background-color: #e8e4dc;
  width: 100%;
  z-index: 5;
  font-size: 1.25rem;
  position: absolute;
  top: 0;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 2rem;
}

.header-left {
  display: flex;
  align-items: center;

  &-mobileLogout {
    display: none;
  }

  & > button {
    margin-left: 1rem;
    font-size: 16px;
    padding: 12px 16px;
    background-color: white;
  }
}

.header-right {
  display: flex;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

li {
  display: inline-flex;
  cursor: pointer;
}

.menu__item__link {
  position: relative;
  padding: 1rem 0 0.5rem;
  margin: 0 1.5rem;
  color: #714219;
  text-decoration: none;
  text-transform: uppercase;

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%) scaleX(0);
    transform-origin: 50% 50%;
    width: 100%;
    height: 1px;
    background-color: #714219;
    transition: transform 250ms;
  }

  &:hover {
    &::after {
      transform: translateX(-50%) scaleX(1);
    }
  }
}

.logo {
  background-color: #fff;
  width: 50%;
  height: 80%;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .menu {
    display: none;
  }

  .header-left {
    &-mobileLogout {
      display: block;
      width: 30%;
      border-radius: 50%;
      background-color: #fff;
      padding: 0.5rem;
    }

    &-desktopLogout {
      display: none;
    }
  }

  .header {
    padding: 0;
    margin: 10px;

    &-right {
      justify-content: end;
      width: 30%;
    }
  }

  .menu-mobile {
    display: block;
    width: 30%;

    &-nav {
      & > ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;

        & > li {
          padding: 1rem;
        }
      }
    }
  }
}
