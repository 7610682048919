@import url('https://fonts.googleapis.com/css2?family=Yatra+One&display=swap');


.intro {
  color: #4056A0;
  background-color: #F1F0EA;
  // background-image: url(../../assets/blop3.svg);
  // background-size: cover;
  // background-repeat: no-repeat;
  display: flex;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;

  
  &-profile {
    width: 30%;
    clip-path: circle();
  }
  
  &-text {
    z-index: 1;
    // transform: scale(2);
    // transition-property: transform;
    // transition-duration: 2s;

    & > img {
      position: absolute;
      width: 60%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      z-index: -5;
    }

    & > h2 {
      font-family: 'yatra one';
      margin-bottom: 0.2em;
      font-size: clamp(3rem, 10vw, 6rem);
    }
    & > span {
      font-size: 1.3rem;
      font-style: italic;
      font-weight: bold;
    }
  }
}

.scroll {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;


  &-button {
    color: #714219;
    position: absolute;
    z-index: 1;
    bottom: 30px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
  
    :hover {
      color: #ffd024;
      opacity: 0.8;
    }
    &-logo {
      opacity: 1;
      -webkit-transition: all .5s ease-in 3s;
      transition: all .5s ease-in 3s;
      color: #714219;
      margin-right: 10px;
      display: block;
      width: 24px;
      height: 24px;
      background-size: 14px auto;
      border-radius: 50%;
      z-index: 2;
      -webkit-animation: bounce 2s infinite 2s;
      animation: bounce 2s infinite 2s;
      -webkit-transition: all .2s ease-in;
      transition: all .2s ease-in;
    }

    &-logo:before {
      position: absolute;
      top: calc(50% - 8px);
      left: calc(50% - 6px);
      transform: rotate(-45deg);
      display: block;
      width: 12px;
      height: 12px;
      content: "";
      border: 2px solid #714219;
      border-width: 0px 0 2px 2px;
  }
  }
}

*,
:after,
:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@keyframes bounce {
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .intro-text {
    & > img {
      width: 100%;
    }
  }

}