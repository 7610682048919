.footer {
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  background-color: #e8e4dc;
  color: grey;

  &-top {
    display: flex;
    width: 100%;

    &-left {
      width: 50%;
      margin: 1.5rem 0;
      padding: 0 1.5rem;
    }

    &-right {
      width: 50%;
      display: flex;
      flex-direction: column;
      border-left: solid 2px white;
      margin: 1.5rem 0;
      padding: 0 1.5rem;

      & > button > div {
        fill: grey;
        stroke: grey;
        width: 15%;
      }

      &-name {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        & > div {
          display: flex;
          flex-direction: column;
          width: 100%;

          &:first-child {
            margin-right: 1rem;
          }

          & > label {
            margin: 20px 0 10px;

            & > em {
              color: red;
            }
          }

          & > input {
            padding: 0.5rem;
            border-radius: 0.3rem;
            border: solid 0.1rem transparent;
          }
        }
      }

      &-compulsoryMessage {
        text-align: end;
        color: red;
        margin: 1rem 0;
        font-size: small;
      }

      & > input,
      textarea {
        padding: 0.5rem;
        border-radius: 0.3rem;
        border: solid 0.1rem transparent;
      }

      // &>:nth-child(7n){
      //   height: 150px;
      // }

      & > label {
        margin: 20px 0 10px;

        & > em {
          color: red;
        }
      }

      &-img {
        position: relative;
        width: 40%;
        display: block;
        margin-bottom: 20px;

        & > img {
          width: 100%;
          height: 100%;
        }

        & > button {
          position: absolute;
          z-index: 1;
          top: 10px;
          right: 10px;
          border-radius: 50%;
          background-color: #ffffff;
          width: 25px;
          height: 25px;
          font-size: x-small;
        }
      }

      & > button {
        display: flex;
        background-color: #fff;
        text-align: center;
        margin: 20px auto 0 auto;
        padding: 12px 16px;
      }
    }
  }

  &-bottom {
    text-align: center;
    padding: 30px 0;
    background-color: white;
  }
}

.hidden {
  display: none;

  &-color {
    color: red;
    font-size: small;
    margin: 0.5rem 0;
  }
}

#phone {
  width: 50%;
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 950px) {
  .footer-top {
    flex-direction: column;
    width: 100%;

    &-left {
      display: none;
    }

    &-right {
      width: 100%;
      border-left: transparent;

      &-name {
        flex-direction: column;
      }
    }
  }

  #phone {
    width: 100%;
  }
}
