.login {
  background-color: #F1F0EA;
  min-height: 100vh;

  // &-img {
  //   position: relative;
  //   left: 40%;
  //   background-color: white;
  //   height: 20%;
  //   width: 20%;
  //   border-radius: 50%;
  // }

  &-form {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%); 
    text-align: center;
    width: 450px;
    border-radius: 15px;
    padding: 3rem 0;
    background-color: white;
    box-shadow: 0 10px 20px hsla(0,0%,0%,.2);
    display: flex;
    align-items: center;
    flex-direction: column;

    & > h2 {
      margin-bottom: 3rem;
    }

   :nth-child(3){
      margin-top: 2rem ;
      // background-color: aqua;
    }

    :nth-child(4){
        border: none;
    }

    & > div {
      width: 350px;
      align-items: center;
      text-align: center;

      & > label {
        margin-top: 0.5rem;

        & > p {
          font-size: small;
        }

        & > input {
          margin-right: 1rem;
        }
      }
    }

    & > div , input {
      display: flex;
      align-items: center;
      border: solid 1px grey;
      border-radius: 5px;

      & > svg {
        margin: 0 20px;
      }

      & > input {
        width: 100%;
        padding: 0.8rem 0;
        border: none;
      }

      :last-child {
        display: flex;
      }
    }

    & > button {
      margin-top: 2rem;
      border-radius: 5px;
      background-color: antiquewhite;
      padding: 1rem;
      width: 350px;
      text-align: center;
    }
  }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .login {
    background-color: white;

    &-form {
      width: 100%;
      box-shadow: none;
      border-radius: 0;
    }
  }
}