@use 'sass:color';
@use '../../vars';

@import url('https://fonts.googleapis.com/css2?family=Finger+Paint&display=swap');

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: grey;
  opacity: 60%;
}

.cards {
  position: relative;

  &-header {
    margin: 1rem 0;

    &-line {
      width: 20%;
      border-bottom: solid 0.1rem #D79922;
    }

    &-title {
      display: flex;
      align-items: center;

      & > button {
        margin: 0 2rem;
        font-size: 14px;
        padding: 8px 10px;
        border-radius: 0.5em;
        background-color: #D79922;
        color: white;
      }

      & > h2 {
        // text-decoration: underline;
        text-underline-offset: 10px;    
        font-weight: bolder;
        padding: 3rem 0;
        color: #4056A1;
        text-align: center;
      }
    }
    
    &-content {
      display: flex;
      flex-direction: column;
      padding: 0 0 3rem;
      align-items: center;


      &>p {
        margin-bottom: 1rem;
      }

      &-category {
        display: flex;
        flex-direction: row;

        & > div {
         flex-direction: row;

          &>label {
            margin: 0 2rem 0 0.5rem;
          }

          & > input[type='radio'] {
            width: 16px;
            height: 16px;
            margin-right: 0.3rem;
          }
        }
      }
    }

    &-return::before {
      transform: scaleX(0);
      transform-origin: bottom right;
    }

    &-return:hover::before {
      transform: scaleX(1);
      transform-origin: bottom left;
    }

    &-return::before {
      content: " ";
      display: block;
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      inset: 0 0 0 0;
      background: #f6b386;
      z-index: -1;
      transition: transform .3s ease;
    }

    &-return {
      position: relative;
      margin: 1rem 10% 3rem;
      display: flex;
      align-items: center;

      &>svg {
        margin-right: 0.5em;
      }
    }
  }

  &-content {  
    margin: 0px 10%;
    columns: 6;
    column-gap: 10px;
    gap: 16px;
    
  }
}

.card {
  border-radius: 1rem;
  background-color: rgba(0, 0, 0, 0.068);
  margin-bottom: 10px;
  break-inside: avoid;
  opacity: 0;
  transition: opacity 0.8s ease-in-out;

  &.active {
    opacity: 1;
  }
  

  &:hover {
    transition: all 0.3s;
    -webkit-transform:scale(1.20); /* Safari et Chrome */
    -moz-transform:scale(1.20); /* Firefox */
    -ms-transform:scale(1.20); /* Internet Explorer 9 */
    -o-transform:scale(1.20); /* Opera */
    transform:scale(1.1);
  }


  &-containeur {
    overflow: hidden;
    border-radius: 8px;
  

    & img {
      width: 100%;
      object-fit: contain;
    }

    &__status {
     display: none;      

    }

    :hover > &__status{
      display: block;
      position: absolute;
      border-radius: 0 0 8px 8px;
      z-index: 1;
      bottom: 0;
      font-size: smaller;
      padding: 0.7em 0;
      background-color: rgba(205, 205, 205, 0.82);
      color: white;
      width: 100%;
      text-align: center;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  &-content {
    text-align: center;
    margin: 1rem 0 1rem 1rem;

    & > div {
      display: flex;
      justify-content: flex-start;
    }

    &__category {
      background-color: #c28c8c;
      color: white;
      font-size: 12px;
      font-weight: 600;
      padding: 6px 8px;
    }

    &__title {
      margin: 0.8em 0;
    }

    &__description {
      font-size: small;
      font-weight: 200;
      margin-bottom: 0.8em;
      color: grey;
    }
  }

 
}

.categoryEmpty {
  text-align: center;

  &-img {
    width: 20%;
  }
}

.get-picture-error{
  text-align: center;
  color: red;
  background-color: rgba(253, 180, 180, 0.5);
  padding: 1.5rem;
  border: solid red 1px;
  width: 600px;
  margin: auto;
}

@media screen and (max-width: 1440px) {
  .cards {  
    &-content {
      columns: 5;
    }
  }
}

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .cards {
    &-content {
      columns: 2;
    }

    &-header {

      &-title > h2 {
        text-align: start;
      }

      &-content > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
  .categoryEmpty-img {
    width: 60%;
  }
}